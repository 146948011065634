<template>
  <div>
    <baseTable :data="allRoles" :column="tableProps">
        <template #projectHeader="{ data }">
          <div>
            <span class="fontWeight">{{ data[0].officium | filterRole}}</span>
          </div>
        </template>
        <template #chinaname="{ data }">
          <p v-for="item in data" :key="item.userName">
            {{ item.chinaname }}
          </p>
        </template>
        <template #userName="{ data }">
          <p v-for="item in data" :key="item.userName">
            {{ item.userName }}
          </p>
        </template>
        <template #email="{ data }">
          <p v-for="item in data" :key="item.userName">
            {{ item.email }}
          </p>
        </template>
      </baseTable>
  </div>
</template>

<script>
import { getUsersWithRole } from "@/api/schedule/index";
import baseTable from "@/components/baseTable.vue";
export default {
  name: "management",
  components: {
    baseTable,
  },
  data() {
    return {
      allRoles:[],
      loading:false,
      tableProps: [
        {
          type: "index",
          label: "序号",
          props: "index",
          style: {
            textAlign: "center",
            minWidth: "60px",
          },
        },
        {
          type: "chinaname",
          label: "成员中文名",
          prop: "chinaname",
          slot: true,
          style: {
            textAlign: "left",
            maxWidth: "80px",
          },
        },
        {
          type: "userName",
          label: "成员用户名",
          prop: "userName",
          slot: true,
          style: {
            textAlign: "left",
            minWidth: "120px",
          },
        },
        {
          type: "email",
          label: "用户邮箱",
          prop: "email",
          slot: true,
          style: {
            textAlign: "center",
            minWidth: "120px",
          },
        },

      ],
    };
  },
  methods: {
    async init(){
      const { data } = await getUsersWithRole();
      const filterData = data.filter(item => item[0].officium!=='Commentator');
      this.allRoles = filterData;
    }
  },
  created() {
    this.init();
  },
};
</script>

<style scoped lang="less">
.fontWeight{
  font-weight: bold;
  font-size: 16px;
}
</style>
